export default {
  NoSms: {
    Title: 'Why do you want to unsubscribe?',
    PhoneNum: 'Mobile Number',
    CancelSubscription: 'Cancel subscription',
    CancelSubscriptionSuccess: 'Unsubscribe Success',
    list: "I don't want recevie these SMS any more, These SMS are too frequent,I did not ask to receive these messages,These SMS are SPAM and have to be reported,Another reasons",
  },
  Toast: {
    MobileNumber: 'Please fill in the correct cell phone number'
  }
}