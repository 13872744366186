import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import "react-toastify/dist/ReactToastify.css"; 
import EventApi from "../api/event";
import storage from "../utils/storage";
import { useTranslation } from "react-i18next";
import wx from "weixin-js-sdk-ts";
import bg_event from "../assets/images/bg_event.png";
import bg_coupon from "../assets/images/bg_coupon.png";
import ic_code1 from "../assets/images/ic_code1.png";
import ic_code2 from "../assets/images/ic_code2.png";
import ic_search from "../assets/images/ic_search.png";
import ProductList from "./components/ProductList";
import { getQueryString, isLogin } from "../utils/util"
import { Toast } from 'zarm';
import 'zarm/dist/zarm.css';


const Event = (props: any) => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);
  const [couponList, setCouponList] = useState([]);

  useEffect(() => {
    // document.title = "中秋节活动"
    // let token = getQueryString('token')
    // console.log(token,"tokentoken");

    storage.set('token',getQueryString('token'))
    storage.set('source',getQueryString('source'))

    

    let id = getQueryString('id')

    console.log(id,"idididid");
    console.log(getQueryString('source'),"idididid");

    // alert(getQueryString('token'))
    

    EventApi.subject(Number(id)).then( res => {
      if(res.code == 200 || res.code == 0){
        document.title = res.data.title || ''

        setCouponList(res.data.coupons)
        
        setList(res.data.products[0].list)
      }
    })

    // storage.set('token','BearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdmF0ZXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL1BGQ0lZNEhWam85NGF2cnhFbUlPN3BManpmeXU3Y0VxR2ZkeERLdUhNQmNzTlNtYWVjQWVZeWM5a094cFhHeEZ1N09lT3R5bG96aWNINFk0d1hhRjJSdy8xMzIiLCJuaWNrbmFtZSI6IjExIiwiaWQiOjQ4MywidHlwZSI6Imp3dCIsImV4cCI6MTYzMjQ1MDk3MywiYWxnIjoiSE1BQzI1NiIsImhlYWRJZCI6MjU2N30.TD8Adqpn_WLq0XsGRacbALsJbtRlR1WP8FENVgfZGKA')
  },[]);

  /**
   * 优惠券列表视图
   *
   * @return {*} 
   */
  const couponView = () => {

    return (
      <div className="row-between pw20">
        {
          couponList.map((item: any,index: number) => {
            return (
              <div style={{position: 'relative'}} key={index}>
                <img src={bg_coupon} style={{width: 102, height:129}} />
                <div className="column-center pt5 " style={{position: 'absolute', left: 0, top: 0,width: 102,height:129}}>
                  <div className="sm" style={{color: '#ff541f'}}>{item.displayName}</div>
                  <div className="row bold" style={{color: '#ff541f', fontSize: 50,lineHeight:1, alignItems: 'end'}}><div style={{paddingBottom: 10,fontSize: 20,color: '#ff541f' }}>$</div>{item.amount}</div>
                  <div 
                    className="xs pw10 ph2 br10" 
                    style={{color: item.isReceive?'#fff':'#ff2e24',backgroundImage:item.isReceive?'linear-gradient(to bottom,#bbb ,#ccc ,#bbb)':'linear-gradient(to bottom,#ffc04e ,#ffd575 ,#ffc04e)'}}
                    onClick={()=>{ 
                      if(!isLogin()){
                        return 
                      }
                      if(item.isReceive) return
                      EventApi.receiveCoupon(item.id).then( res =>{
                        if(res.code == 200 || res.code == 0){
                          Toast.show("领取成功！");
                          (couponList[index] as any).isReceive = true
                          setCouponList([...couponList])
                        }else{
                          Toast.show(res.msg);
                        }                        
                      })
                    }}
                  >{item.isReceive?'已领取':'立即领取'}</div>
                </div>
              </div>
            )
          })
        }
        {/* <div className="row-between wrap pw15">
          {
            voupon.map((item:any) => {
              return (
                <div
                  className="row-center"
                  style={{borderRadius: 8, justifyContent: 'center', backgroundColor: "linear-gradient(to top left, #fee7e8, #fff)", width: 110, height: 70, color: '#fe1825'}}
                >
                  
                  <div className="column-center flex1 ">
                    <div className="bold" style={{fontSize: 26,color: '#fe1825'}}>80</div>
                    <div className="xs" style={{color: '#fe1825'}}>满100元可用</div>
                  </div>
                  <div className="pr10 sm" style={{width: 12,color: '#fe1825'}}>领取</div>

                </div>
              )
            })
          }
        
        </div> */}
      </div>
    )
  }


  const itemTitle = (title: string) => {
    return (
      <div className="row-center">
        <div className="br5 mr10" style={{width: 2,height: 5, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 7, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 9, backgroundColor: '#41aab4'}}></div>
        <div className="position-re" style={{}}>
          <div className="xxl position-re" style={{color: '#41aab4', zIndex: 3,}}>{title}</div>
          <div className="position-ab br5" style={{zIndex:2, left: -5,bottom: 4, width: 80, height: 4, backgroundColor: 'rgba(255,197,146, 0.8)',}}></div>
        </div>
        <div className="br5 ml10 mr10" style={{width: 2,height: 9, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 7, backgroundColor: '#41aab4'}}></div>
        <div className="br5 " style={{width: 2,height: 5, backgroundColor: '#41aab4'}}></div>
      </div>
    )
  }


  /**
   * 活动商品列表视图
   *
   */
  const goodsList = () => {

    return (
      <div className="pw20 mt10">
        <div className="event-border column-center ph10" style={{}}>
          {itemTitle('活动商品')}
          {list.length > 0?<ProductList list={list} />:null}
          
        </div>
      </div>
    )
  }



  /**
   * 活动规则视图
   *
   */
  const rulesView = () => {
    const textList = [
      '1. 活动参与方式：Fresh4ALL新老用户均可以参与活动。',
      '2. 中秋专属优惠券：在活动期间会自动发放入您的账户当中。',
      '3. 分享活动：邀请好友免费领取5元优惠券，被邀请对象仅限新用户。',
      '4. 新人礼包：新用户注册后可在新人专区领取。',
      '5. 老用户何时获得奖励：您可在好友领取并使用您分享的优惠券之日起3个工作日内获得邀请奖励。',
      '6. 活动结束时间：活动截止时间为2020年9月21日24时。',
    ]
    return (
      <div className="pw20 mt10">
        <div className="event-border ph15 pw10 column-center" style={{}}>
          {itemTitle('活动规则')}
          <div className="column mt20">
            {
              textList.map((item: any,index: number) => {
                return <div className="xxs mt" style={{lineHeight: 1.8}} key={index}>{item}</div>
              })
            }
          </div>
        </div>
      </div>
    )
  }

  /**
   * 底部二维码
   *
   */
  const codeView = () => {

    return (
      <div className="pw20 mt10">
        <div className="event-border ph15 pw10  column-center" style={{}}>
          <div className="row-around w100">
            <div className="column-center ">
              <img src={ic_code1} style={{width: 75, height: 75}} />
              <div className="mt5" style={{fontSize: 12}}>扫描二维码立即下载APP</div>
            </div>
            <div className="column-center ">
              <img src={ic_code2} style={{width: 75, height: 75}} />
              <div className="mt5" style={{fontSize: 12}}>扫描二维码进入小程序</div>
            </div>
          </div>
          <div className="event-border row-between position-re mt10" style={{width: 160,height: 22, backgroundColor: '#fff'}}>
            <div className="xxs bold text-center flex1" >www.fresh4all.sg</div>
            <div className="row-center" style={{width: 24}}>
             <img src={ic_search} style={{width: 10, height: 10}} />
            </div>
            <div className="position-ab" style={{ width: 2, height: 20, right: 24, top: 0,backgroundColor: '#4bb6c0'}}></div>
          </div>
        </div>
      </div>
    )
  }



  return (
    <div
      className="column body"
      style={{ backgroundImage:'linear-gradient(to bottom,#3c909e 10%,#143c56 40%,#143c56)', alignItems: "center", height: '100%'}}
    >
      {/* <div className="position-ab br20 sm bold pw10 ph2" style={{ zIndex: 2, top: 40, left: 20, backgroundColor: '#4bb6c0',color: '#fff' }}>Fresh4ALL与您一起过中秋</div> */}

      <img src={bg_event} className="position-ab" style={{width: '100%'}} />
      <div className="w100 pb20" style={{marginTop: 320}}>
        {couponView()}
        {goodsList()}
        {rulesView()}
        {codeView()}
      </div>
    </div>
  );
};

export default Event;
