import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import {
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MerchantApi from "../api/merchant";
import storage from "../utils/storage";
import { useTranslation } from "react-i18next";
import wx from "weixin-js-sdk-ts";
import bg_img from "../assets/images/bg_merchant.png";
import bg_event from "../assets/images/bg_event.png";
import bg_coupon from "../assets/images/bg_coupon.png";
import bg_new from "../assets/images/bg_new.png";
import ic_process from "../assets/images/ic_process.png";
import ic_process1 from "../assets/images/ic_process1.png";
import ic_process2 from "../assets/images/ic_process2.png";
import ic_process3 from "../assets/images/ic_process3.png";
import ic_code1 from "../assets/images/ic_code1.png";
import ic_code2 from "../assets/images/ic_code2.png";
import ic_search from "../assets/images/ic_search.png";

import ProductList from "./components/ProductList";


const Invitation = (props: any) => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);
  const [token, setToken] = useState('');

  useEffect(() => {
    document.title = "邀请好友"
    storage.set('invitationId',getQueryString('invitationId'))


    // storage.set('token','BearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdmF0ZXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL1BGQ0lZNEhWam85NGF2cnhFbUlPN3BManpmeXU3Y0VxR2ZkeERLdUhNQmNzTlNtYWVjQWVZeWM5a094cFhHeEZ1N09lT3R5bG96aWNINFk0d1hhRjJSdy8xMzIiLCJuaWNrbmFtZSI6IjExIiwiaWQiOjQ4MywidHlwZSI6Imp3dCIsImV4cCI6MTYzMjQ1MDk3MywiYWxnIjoiSE1BQzI1NiIsImhlYWRJZCI6MjU2N30.TD8Adqpn_WLq0XsGRacbALsJbtRlR1WP8FENVgfZGKA')
  },[]);


  function getQueryString(name:string) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }


  const showToast = (text: string) => {
    toast.info(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };


  /**
   * 新人专区视图
   *
   * @return {*} 
   */
  const newcomerView = () => {
    let coupon = [
      {
        num: 80,
        text: '满100可用'
      },
      {
        num: 80,
        text: '满100可用'
      },
      {
        num: 80,
        text: '满100可用'
      },
      {
        num: 80,
        text: '满100可用'
      },
    ]
    let line = [0,1,2]
    return(
      <div className="pt10 position-re column-center" >
        <img src={bg_new} style={{ width: '100%'}} />
        <div className="position-ab w100 column-center" style={{zIndex: 2,bottom: 12,}}>
          <div className="row-between " style={{ width: 270}}>
            {
              coupon.map((item: any) => {
                return (
                  <div className="column-center">
                    <div className="row bold" style={{color: '#41aab4', fontSize: 50, lineHeight:0.8, alignItems: 'end'}}><div style={{paddingBottom: 6,fontSize: 20,color: '#41aab4' }}>$</div>5</div>
                    <div className="fz8 " style={{ fontSize: 8, color: '#41aab4'}}>蔬菜优惠券</div>
                  </div>
                )
              })
            }
          </div>
          
          <div className="fz8" style={{ fontSize: 6, color: '#41aab4', marginTop: 2}}>*优惠券限全品类通用*</div>
        </div>
        <div className="row-between position-ab" style={{width: 140, zIndex: 2,bottom: 35,}}>
            {
              line.map(() => {
                return (
                  <div style={{width: 1, height: 50, backgroundColor: '#41aab4'}}></div>
                )
              })
            }
          </div>
      </div>
    )

  }

  const itemTitle = (title: string) => {
    return (
      <div className="row-center">
        <div className="br5 mr10" style={{width: 2,height: 5, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 7, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 9, backgroundColor: '#41aab4'}}></div>
        <div className="position-re" style={{}}>
          <div className="xxl position-re" style={{color: '#41aab4', zIndex: 3,}}>{title}</div>
          <div className="position-ab br5" style={{zIndex:2, left: -5,bottom: 4, width: 80, height: 4, backgroundColor: 'rgba(255,197,146, 0.8)',}}></div>
        </div>
        <div className="br5 ml10 mr10" style={{width: 2,height: 9, backgroundColor: '#41aab4'}}></div>
        <div className="br5 mr10" style={{width: 2,height: 7, backgroundColor: '#41aab4'}}></div>
        <div className="br5 " style={{width: 2,height: 5, backgroundColor: '#41aab4'}}></div>
      </div>
    )
  }

  /**
   * 步骤视图
   *
   */
  const processView = () => {
    return (
      <div className="pw20 mt10">
        <div className="event-border ph15 column-center" style={{}}>
          {itemTitle('邀请流程')}
          <div className="row-between mt20">
            <div className="column-center" style={{width: 92}}>
              <img src={ic_process1} style={{width: 45, height: 45}} />
              <div className="fz8 mt5" style={{fontSize: 6,}}>邀请新人好友</div>
            </div>
            <img src={ic_process} className="mb15" style={{width: 12, height: 11}} />
            <div className="column-center">
              <img src={ic_process2} style={{width: 45, height: 45}} />
              <div className="fz8 mt5" style={{fontSize: 8,}}>好友领取优惠券并消费</div>
            </div>
            <img src={ic_process} className="mb15" style={{width: 12, height: 11}} />
            <div className="column-center" style={{width: 92}}>
              <img src={ic_process3} style={{width: 45, height: 45}} />
              <div className="fz8 mt5" style={{fontSize: 8,}}>您获得5元优惠券</div>
            </div>
          </div>
          <div className="mt20 pw20 ph5 br20 bold" style={{color: '#fff', backgroundColor: '#4cb6c0', }}>立即邀请好友</div>
        </div>
      </div>
    )
  }

  /**
   * 活动规则视图
   *
   */
  const rulesView = () => {
    const textList = [
      '1. 活动参与方式：Fresh4ALL新老用户均可以参与活动。',
      '2. 中秋专属优惠券：在活动期间会自动发放入您的账户当中。',
      '3. 分享活动：邀请好友免费领取5元优惠券，被邀请对象仅限新用户。',
      '4. 新人礼包：新用户注册后可在新人专区领取。',
      '5. 老用户何时获得奖励：您可在好友领取并使用您分享的优惠券之日起3个工作日内获得邀请奖励。',
      '6. 活动结束时间：活动截止时间为2020年9月21日24时。',
    ]
    return (
      <div className="pw20 mt10">
        <div className="event-border ph15 pw10 column-center" style={{}}>
          {itemTitle('活动规则')}
          <div className="column mt20">
            {
              textList.map((item: any) => {
                return <div className="xxs mt" style={{lineHeight: 1.8}}>{item}</div>
              })
            }
          </div>
        </div>
      </div>
    )
  }

  /**
   * 活动规则视图
   *
   */
  const codeView = () => {

    return (
      <div className="pw20 mt10">
        <div className="event-border ph15 pw10 column-center" style={{}}>
          <div className="row-between">
            <div className="column-center ">
              <img src={ic_code1} style={{width: 75, height: 75}} />
              <div className="fz8 mt5" style={{fontSize: 8}}>扫描二维码立即下载APP</div>
            </div>
            <div className="column-center ">
              <img src={ic_code2} style={{width: 75, height: 75}} />
              <div className="fz8 mt5" style={{fontSize: 8}}>扫描二维码进入小程序</div>
            </div>
          </div>
          <div className="event-border row-between position-re mt10" style={{width: 160,height: 20, backgroundColor: '#fff'}}>
            <div className="xxs bold text-center flex1" >www.fresh4all.sg</div>
            <div className="row-center" style={{width: 24}}>
             <img src={ic_search} style={{width: 10, height: 10}} />
            </div>
            <div className="position-ab" style={{ width: 2, height: 20, right: 24, top: 0,backgroundColor: '#4bb6c0'}}></div>
          </div>
        </div>
      </div>
    )
  }



  return (
    <div
      className="column body"
      style={{ backgroundImage:'linear-gradient(to bottom,#3c909e 10%,#143c56 40%,#143c56)', alignItems: "center", height: '100%'}}
    >

      {/* {listView()} */}
      <div className="w100 pb20">
        {newcomerView()}
        {processView()}
        {rulesView()}
        {codeView()}
      </div>
    {/* <ProductList  /> */}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default Invitation;
