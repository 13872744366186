export default {
  NoSms: {
    Title: '你为什么想要取消订阅？',
    PhoneNum: '手机号',
    CancelSubscription: '取消订阅',
    CancelSubscriptionSuccess: '取消订阅成功',
    list: "我不想再接收这些短信,这些短信过于频繁,我没有要求接收这些短信,这些短信是不相关的,这些是垃圾短信，必须举报,其他原因",
  },
  Toast: {
    MobileNumber: '请填写正确手机号码'
  }
}