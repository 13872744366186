
const storage = {
  set(key: string, value: any){
    localStorage.setItem(key, JSON.stringify(value))
  },

  get(key: string){
    let data:any = localStorage.getItem(key)
    return JSON.parse(data)
  },

  del(key: string){
    localStorage.removeItem(key)
  }
}


export default storage