import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import {
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MerchantApi from "../api/merchant";
import storage from "../utils/storage";
import { useTranslation } from "react-i18next";
import wx from "weixin-js-sdk-ts";
import bg_img from "../assets/images/bg_merchant.png";



const Merchant = () => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "商户列表"
    MerchantApi.list({}).then( res => {
      // res.data.push(res.data[0])
      // res.data.push(res.data[0])
      // res.data.push(res.data[0])
      // res.data.push(res.data[0])
      // res.data.push(res.data[0])
      // res.data.push(res.data[0])

      setList(res.data)
      
    })
    // storage.set('token','BearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdmF0ZXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL1BGQ0lZNEhWam85NGF2cnhFbUlPN3BManpmeXU3Y0VxR2ZkeERLdUhNQmNzTlNtYWVjQWVZeWM5a094cFhHeEZ1N09lT3R5bG96aWNINFk0d1hhRjJSdy8xMzIiLCJuaWNrbmFtZSI6IjExIiwiaWQiOjQ4MywidHlwZSI6Imp3dCIsImV4cCI6MTYzMjQ1MDk3MywiYWxnIjoiSE1BQzI1NiIsImhlYWRJZCI6MjU2N30.TD8Adqpn_WLq0XsGRacbALsJbtRlR1WP8FENVgfZGKA')
  },[]);

  const showToast = (text: string) => {
    toast.info(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  


  const listView = ()=>{
    
    return (
      <div style={{padding: 15, width: '92%', paddingBottom: 180}}>
        {list.map((item: any,index: number) =>{
          return (
            <div 
              key={index} 
              style={{display: 'flex', backgroundColor: '#fff', borderRadius: 10, marginBottom: 10, padding: 10,overflow: 'hidden'}}
              onClick={()=>{
                wx.miniProgram.navigateTo({url: `/pagesA/merchant/merchant?id=${item.id}`})
              }}
            >
              <img src={item.previewUrl} style={{width: 80, height: 80, borderRadius: 8,}} />
              <div className="column-between ml10 flex1" style={{overflow: 'hidden'}} >
                <div className="one-txt-cut" style={{ fontSize: 17, fontWeight: 'bold', }}>{item.name}</div>
                <div style={{ fontSize: 12, color: '#999'}}>{item.businessHours} {item.perCapita}</div>
                <div style={{ fontSize: 13, display: 'flex', flexWrap: 'wrap'}}>
                  {
                    item.cnFeature.split(",").map( (item:any) => {
                      return (
                        <div style={{backgroundColor: '#f5f5f5', marginTop: 3, padding: 2, paddingLeft: 6, paddingRight: 6, color: '#999', borderRadius: 6, marginRight: 5, marginBottom: 5, fontSize: 11}}>{item}</div>
                      )
                    })
                  }
                </div>
                <div style={{ fontSize: 12, color: '#999'}}>{item.address}</div>

              </div>
            </div>
          )
        })}
        
      </div>
    )

  }




  return (
    <div
      className="column body"
      style={{ backgroundColor: "#72b341", alignItems: "center", height: '100%'}}
    >

      <img src={bg_img} style={{width: '100%'}} />
      {listView()}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default Merchant;
