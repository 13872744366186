import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import wx from "weixin-js-sdk-ts";
import Product from "../../api/product";
import { isLogin } from "../../utils/util";
import { Toast } from 'zarm';
import 'zarm/dist/zarm.css';
import storage from "../../utils/storage";


var script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.src = 'https://appx/web-view.min.js';
document.head.appendChild(script);  


const ProductList = (props: any) => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState(props.list);
  const [alipay, setAlipay] = useState(props);
  const [source, setSource] = useState('');


  useEffect(() => {
    
    console.log(props,"goodsListgoodsListgoodsList");
    setAlipay((window as any).my)
    setSource(storage.get('source'))

    // alert(fnc().navigateTo)
    // alert(alipay.navigateTo)
    // console.log(fnc());
    
  },[]);

  const addCart = (id: number) => {
    if(!isLogin()){
      return 
    }

    Product.addCart(id).then( res => {
      console.log(res,"ddd");
      if(res.code == 200 || res.code == 0){
        Toast.show("添加购物车成功！");
      }else{
        Toast.show(res.msg);
      }
    })

  }

  const listView = ()=>{
    return (
      <div className="w100">
        <div className="row-between wrap pw10">
          {
            list.map((item: any, index: number) => {
              return (
                <div 
                  key={index}
                  className="br10 mb10 over-hidden bg-white" 
                  style={{width: '48%', position:'relative' }} 
                  onClick={()=>{
                    console.log(item);
                    // console.log(script);
                    if(source == 'alipay'){
                      (window as any).my.navigateTo({url: `/pagesA/product/product?id=${item.productId}&locale=${item.locale}`})
                    }else{
                      wx.miniProgram.navigateTo({url: `/pagesA/product/product?id=${item.productId}&locale=${item.locale}`})
                    }
                    
                  }}
                >
                  <img src={item.pic  + '!160'} style={{width: '100%', height: 167}} />
                  <div className="nr mt5 pw10 one-txt-cut" >{item.displayName}</div>
                  <div className="sm mt5 pw10 mina-color one-txt-cut">{item.displaySubTitle}</div>

                  <div className="discount-wrap pw10 mt5 row" v-if="item.promotionEnable">
                    {item.promotionEnable?<div className="br8 border-red xs"  style={{padding: 3, color: '#FF541F'}} >{item.offerType == 'deduction'?'秒杀':item.offerValue / 10 + '折'}</div>:null}
                    {item.promotionEnable?<div className="original nr line-through ml5" v-if="item.promotionEnable" style={{color: '#999'}}>${ item.originPrice }</div>:null}
                  </div>

                  <div className="row pd10">
                    <div className="bold md" style={{color: '#FF541F'}}>${ item.price }</div>
                    <div className="ml5 xs" style={{color: '#999'}}>/{ item.specName }</div>
                  </div>

                  <div 
                    className="flex center br15" 
                    style={{width: 30, fontSize: 30, height: 30, color: '#fff', backgroundColor:'#67C066', position: 'absolute', bottom: 8, right: 8}}
                    onClick={(e)=>{
                      // 阻止冒泡
                      e.stopPropagation()
                      // e.nativeEvent.stopImmediatePropagation()
                      console.log(item,"加入购物车");
                      addCart(item.id)
                    
                    }}
                  ><div className="pb5" style={{color: '#fff'}}>+</div></div>
                </div>
              )
            })
          }
        </div>
      </div>
    )

  }


  return (
    <div
      className="column body pt10"
      style={{ alignItems: "center", height: '100%', width: '100%'}}
    >
      {listView()}
    </div>
  );
};

export default ProductList;
