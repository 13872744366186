
import wx from "weixin-js-sdk-ts";
import storage from "../utils/storage";


/**
 * 判断用户登录状态
 *
 * @export
 * @return {*} 
 */
export function isLogin(){
  let source = storage.get('source')

  if(storage.get('token')){
    return true
  }else{
    // 跳转微信登录页面
    if(source == 'alipay'){
      (window as any).my.navigateTo({url: `/pages/public/login`})
    }else{
      wx.miniProgram.navigateTo({url: `/pages/public/login`})
    }
    return false
  }
}

/**
 * 获取url参数
 *
 * @export
 * @param {string} name 参数名称
 * @return {*} 
 */
export function getQueryString(name:string) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}





