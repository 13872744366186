import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import {
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import no_sms from "../assets/images/NO-SMS.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SMSService from "../api/cancelSMS";
import storage from "../utils/storage";
import { useTranslation } from "react-i18next";

const NoSms = () => {
  const { t, i18n } = useTranslation();
  let _list = t("NoSms.list").split(",");

  const [areaCode, setAreaCode] = useState("+65");
  const [phone, setPhone] = useState("");
  const [desc, setDesc]: any = useState(_list[0]);
  const [isCancel, setIsCancel] = useState(false);
  const [list, setList] = useState(_list);

  // i18n.changeLanguage('en')

  useEffect(() => {
    // storage.set('token','BearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdmF0ZXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL1BGQ0lZNEhWam85NGF2cnhFbUlPN3BManpmeXU3Y0VxR2ZkeERLdUhNQmNzTlNtYWVjQWVZeWM5a094cFhHeEZ1N09lT3R5bG96aWNINFk0d1hhRjJSdy8xMzIiLCJuaWNrbmFtZSI6IjExIiwiaWQiOjQ4MywidHlwZSI6Imp3dCIsImV4cCI6MTYzMjQ1MDk3MywiYWxnIjoiSE1BQzI1NiIsImhlYWRJZCI6MjU2N30.TD8Adqpn_WLq0XsGRacbALsJbtRlR1WP8FENVgfZGKA')
  });

  const showToast = (text: string) => {
    toast.info(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const phoneChange = (text: string) => {
    if (text.length > 11) return;
    if (text.length > 8) {
      setAreaCode("+86");
    } else {
      setAreaCode("+65");
    }
    setPhone(text);
  };

  const cancelBtn = () => {
    if (areaCode == "+65") {
      if (!/^\d{8}$/.test(phone)) {
        showToast(t("Toast.MobileNumber"));
        return;
      }
    }

    if (areaCode == "+86") {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        showToast(t("Toast.MobileNumber"));
        return true;
      }
    }

    // 请求测试
    SMSService.create({
      phone: areaCode + phone,
      note: desc
    });
    setIsCancel(true);
  };

  const cancelView = () => {
    return (
      <div className="column column-center">
        <div className="xxl mt20 mb20">{t("NoSms.Title")}</div>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={desc}
            variant="outlined"
            // autoWidth={true}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          >
            {list.map((item: any, index: number) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="mt20 mb20">
          <TextField
            id="standard-basic"
            error={false}
            label={t("NoSms.PhoneNum")}
            value={phone}
            type="number"
            onChange={(event) => {
              phoneChange(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{areaCode}</InputAdornment>
              )
            }}
          />
        </div>
        <div style={{}}>
          <Button
            className="xxs"
            variant="contained"
            color="primary"
            onClick={() => {
              cancelBtn();
            }}
          >
            {t("NoSms.CancelSubscription")}
          </Button>
        </div>
      </div>
    );
  };

  const successView = () => {
    return (
      <div className="mt20 mb20 bold xxl" style={{ color: "#00b900" }}>
        {" "}
        {t("NoSms.CancelSubscriptionSuccess")}{" "}
      </div>
    );
  };

  return (
    <div
      className="body column "
      style={{ backgroundColor: "#1976d2", alignItems: "center" }}
    >
      <img
        src={no_sms}
        style={{ width: 160, height: 44, marginTop: 80, marginBottom: 40 }}
      ></img>
      <div
        className="bg-white column column-center br20 "
        style={{ padding: 15, width: "80%" }}
      >
        {isCancel ? successView() : cancelView()}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default NoSms;
