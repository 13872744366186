import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cn from './cn';
import en from './en';

// console.log(i18n.use(LanguageDetector),"LanguageDetectorLanguageDetector");

i18n.use(LanguageDetector) //嗅探当前浏览器语言
.use(initReactI18next) //init i18next
.init({
  //引入资源文件
  resources: {
    en: {
      translation: en,
    },
    zh: {
      translation: cn,
    },
    zn: {
      translation: cn,
    },
  },
  //选择默认语言，选择内容为上述配置中的key，即en/zh
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n;