import nosms from "../pages/NoSms";
import AlipayActivity from "../pages/AlipayActivity";
import Merchant from "../pages/Merchant"
import Event from "../pages/Event"
import Invitation from "../pages/Invitation"

interface router {
  path: string;
  component: any;
  children?: Array<router>;
}

const routers: Array<router> = [
  {
    path: "/nosms",
    component: nosms
  },
  {
    path: "/alipay",
    component: AlipayActivity
  },
  {
    path: "/merchant",
    component: Merchant
  },
  {
    path: "/event",
    component: Event
  },
  {
    path: "/invitation",
    component: Invitation
  }
];
export default routers;
