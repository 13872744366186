/*
 * @Author: your name
 * @Date: 2021-08-23 16:17:21
 * @LastEditTime: 2021-08-28 17:43:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fresh4all-app/src/utils/httpApi.ts
 */
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
// import { host } from "./request";
// import * as RNLocalize from "react-native-localize";
// import { Locale } from "react-native-localize";
import { arrayEach, get } from "xe-utils";
import storage from "./storage";

// import { Toast } from "@ant-design/react-native";

export interface Result<T> {
    code: number,
    message: string,
    msg: string,
    data?: T,
    totalCount?: number,
}


// const host = 'http://gateway.tuesday-tech.cc/'
const host = 'https://gateway.tuesday-tech.com/'




const channel: number = 2;
// const locales: Locale[] = RNLocalize.getLocales();
const locales:[] = [];



const instance = axios.create({
    baseURL: host,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept-Language': 'zh-CN',

    },
    validateStatus: () => true
});


instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    config.headers['Authorization'] = await storage.get('token') || ''
    config.params = { ...(config.params || {}), channel: channel };

    if (config.method && ['post', 'put', 'patch'].indexOf(config.method) != -1) {
        config.data = { ...(config.data || {}), channel: channel };
    }

    return config;
}, (error: any) => {
    return Promise.reject(error);
})

instance.interceptors.response.use((response: AxiosResponse<Result<any>>) => {
    console.log(response, 3444)
    // const { code, message } = response.data;
    switch (response.status) {
        case 401:
            break;
    }
    
    return response;
}, (error) => {
    // Toast.info(error.message);
});


interface RequestInstance {
    get<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    head<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    options<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>>;
}

const handle = (method: Method) => {
    return <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Result<T>> => {
        const options: AxiosRequestConfig = {
            ...(config || {}),
            method: method,
            url: url,
        };

        if (method == 'get') {
            options.params = data;
        } else {
            options.data = data;
        }

        return new Promise((resolve, reject) => {
            instance.request<Result<T>>(options).then(res => {
                resolve(res.data);
            })
        })
    }
}

const request: RequestInstance = {
    get: handle('get'),
    delete: handle('delete'),
    head: handle('head'),
    options: handle('options'),
    post: handle('post'),
    put: handle('put'),
    patch: handle('patch')
}

export default request
