import { AxiosRequestConfig } from "axios"
import request, { Result } from "../utils/httpApi"

interface Cancel {

}



class Product {

  public static addCart = (id: number) => {
    return request.post(`portal/api/single/cart/${id}/add`)
  }


}

export default Product