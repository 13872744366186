import { AxiosRequestConfig } from "axios"
import request, { Result } from "../utils/httpApi"

interface Cancel {
  phone: string,
  note: string,
}



class Merchant {

  public static list = (data?:any, config?: AxiosRequestConfig) => {
    return request.get('cms/merchant/list', data, config)
  }

  public static goodsList = (data?:any, config?: AxiosRequestConfig) => {
    return request.get('cms/merchant/list', data, config)
  }


}

export default Merchant