import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import alipay01 from "../assets/images/alipay01.jpg";
import { ToastContainer, toast } from "react-toastify";

const AlipayActivity = () => {
  useEffect(() => {
    window.location.href =
      "alipays://platformapi/startapp?appId=2021002153658749";
  });
  return (
    <div
      className="body column "
      style={{ backgroundColor: "#1976d2", alignItems: "center" }}
    >
      <img src={alipay01} style={{ width: "100%" }}></img>
    </div>
  );
};

export default AlipayActivity;
