import React, { Component } from "react";
import NoSms from "./NoSms";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../language/index";
import routers from "../router/index";
var script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.src = 'https://appx/web-view.min.js';
document.head.appendChild(script); 

class App extends Component<any, any> {
  render(): React.ReactNode {
    return (
      <BrowserRouter>
        {routers.map((router) => {
          return (
            <Route
              key={router.path}
              path={router.path}
              component={router.component}
            ></Route>
          );
        })}
      </BrowserRouter>
    );
  }
}

export default App;
