import { AxiosRequestConfig } from "axios"
import request, { Result } from "../utils/httpApi"

interface Cancel {

}



class event {

  public static receiveCoupon = (id: number) => {
    return request.post(`oms/v1/coupon/${id}/receive`)
  }

  public static subject = (id: number) => {
    return request.get(`cms/subject/${id}`)
  }

}

export default event