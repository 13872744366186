import { AxiosRequestConfig } from "axios"
import request, { Result } from "../utils/httpApi"

interface Cancel {
  phone: string,
  note: string,
}



class SMSService {
  public static create = (data: Cancel, config?: AxiosRequestConfig) => {
    return request.post('push/v1/send/unsubscribe', data, config)
  }

  public static test = (data?:any, config?: AxiosRequestConfig) => {
    return request.get('oms/v1/cart/list', data, config)
  }
}

export default SMSService